
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";
import bodymovin from 'lottie-web';

@Options({
    components: {
    }
})
export default class CompleteOnBoardingModal extends Vue {

    @Prop() confirm: () => void;
    @Prop() deny: () => void;

    created(){
    }

    mounted(){
        setTimeout(() => {
            var animation = bodymovin.loadAnimation({
                container: <any>this.$refs.lottieAnimation,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/registrazioneriuscita.json'
            })

            animation.play();
            animation.loop = false;
        }, 300);
    }

}
