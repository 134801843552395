
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { AdminClient, AppUserClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import { StorageServices } from '@/services/StorageServices';
import { getMaxRangeLabel, getUniversalISOStringDate } from '@/utils';
import store from '@/store';
import CompleteOnBoardingModal from '../modals/completeOnBoardingModal.vue';
import InfoCompleteProfileModal from '@/components/modals/InfoCompleteProfileModal.vue';
import { LoginServices } from '@/services/LoginServices';

@Options({
    components: {
    }
})
export default class CompleteOnBoarding extends Vue {

    slideIndex: number = 0;
    model: OM.ConfirmOnboardingVM = new OM.ConfirmOnboardingVM();
    
    sexualOrientations: OM.TextIdentifier[] = [];
    ownGenderList: OM.TextIdentifier[] = [];
    genderList: OM.TextIdentifier[] = [];
    ageSettings: OM.AgeRangeConfiguration = new OM.AgeRangeConfiguration();

    created() {
        this.ageSettings = store.state.consts.config.ageRange;
        this.model.gender = null;
        this.model.lookingFor = null;
        this.model.showSexualOrientation = true;
        this.model.showAge = true;
        this.model.ageRange.maxAge = this.ageSettings.maxVisible;
        this.model.ageRange.minAge = this.ageSettings.min;

        this.sexualOrientations = store.state.consts.sexualOrientation;
        this.ownGenderList = store.state.consts.ownGenderList;
        this.genderList = store.state.consts.genderList;
    }

    get isDisabled(){
        if(this.slideIndex == 0)
            return !this.model.name;
        else if(this.slideIndex == 1)
            return !this.model.birthDate;
        else if(this.slideIndex == 2)
            return !this.model.gender;
        else if(this.slideIndex == 3)
            return !this.model.sexualOrientation;

        return !this.model.gender || !this.model.birthDate || !this.model.lookingFor || !this.model.name || !this.model.sexualOrientation;
    }

    get getMaxRange(){
        return getMaxRangeLabel(this.model.ageRange, this.ageSettings);
    }

    UpdateValues(val: any){
        this.model.ageRange.minAge = val.minValue;
        this.model.ageRange.maxAge = val.maxValue;
    }

    next(){
        if(this.slideIndex < 4){
            this.slideIndex++;
            return;
        }

        var vm = JSON.parse(JSON.stringify(this.model));
        vm.birthDate = getUniversalISOStringDate(new Date(vm.birthDate))
        AppUserClient.confirmOnBoarding(vm)
        .then( x => {
            x.token = store.state.loggedUser.token;
            x.refreshToken = store.state.loggedUser.refreshToken;
            StorageServices.setLoggedUser(x);
            store.state.loggedUser = x;

            this.$opModal.show(CompleteOnBoardingModal, {
                confirm: () => {
                    this.$opModal.closeLast();
                    this.openCompleteProfileModal();
                },
                deny: () => {
                    this.$opModal.closeLast();
                    LoginServices.LoginCallback(x.token,  x.refreshToken);
                }
            })
        })
        .catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    openCompleteProfileModal(){
        this.$opModal.show(InfoCompleteProfileModal, {
            onboardingpath: true,
            confirm: () => {
                this.$opModal.closeLast();
                LoginServices.LoginCallback(store.state.loggedUser.token, store.state.loggedUser.refreshToken, true);
            },
            deny: () => {
                this.$opModal.closeLast();
                LoginServices.LoginCallback(store.state.loggedUser.token, store.state.loggedUser.refreshToken);
            }
        })
    }

}
